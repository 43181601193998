// @flow

import React from 'react';
import { connect } from 'react-redux';
import Topic from '../../shared/topic/Topic';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import NavContainer from '../../shared/nav/NavContainer';
import { isMyPathPeripartum } from '../../../lib/appMode';
import { getIsOptionalPageVisited } from '../../mypath_peripartum/FertilityAfterBirth/FertilityAfterBirth';
import './PregnantAgain.scss';
import ImgMomToddler from './MomToddler.png';
import type { PageProps, State } from '../../../lib/types';

const PregnantAgainFirst = ({
  lexicon,
  sharedLexicon,
  isNextButtonHidden,
  locale,
  navLinks,
  isAnyModalVisible
}: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={lexicon.get('headings')} />
    <div className="flex-group">
      <div className="image-space regular" />
      <MarkdownBlock str={lexicon.get('paragraphs')} />

      { isMyPathPeripartum && (
        <>
          <div className="topics-container">
            { lexicon.get('topics').map((topic: string, index: number): React.element<*> => (
              <Topic
                key={topic}
                topic={topic}
                locale={locale}
                ariaHidden={isAnyModalVisible}
                isFirst={index === 0}
              />
            ))}
          </div>

          <div className="image-container">
            <img src={ImgMomToddler} alt="" />
          </div>
        </>
      )}
    </div>

    { isMyPathPeripartum && (
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        backPage={getIsOptionalPageVisited() ? 'fertility' : 'fertility_after_birth'}
        sharedLexicon={sharedLexicon}
      />
    )}
  </>
);

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(PregnantAgainFirst);
