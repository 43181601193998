// @flow

import React from 'react';
import IconInControl from './inControl_modified.png';
import './BackToInControlButton.scss';

type BackToInControlButtonProps = {
  text: string,
  extraClass: string,
};

const BackToInControlButton: React.FC = ({ text, extraClass = '' }: BackToInControlButtonProps) => (
  <div className={`back-to-in-control close ${extraClass}`}>
    <a href={process.env.REACT_APP_RETURN_LINK} className="button">
      <span>{text}</span>
      <img src={IconInControl} alt="In Control" />
    </a>
  </div>
);

export default BackToInControlButton;
