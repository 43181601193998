// @flow

import React from 'react';
import NavButton from '../../shared/nav/NavButton';
import type { PageProps } from '../../../lib/types';
import FooterMP from '../../shared/footers/FooterMP';
import MyReproductiveHealthJourney from './MyReproductiveHealthJourney';
import './SplashMP.scss';
import { isMyPathVAStudy, isMyPath4you } from '../../../lib/appMode';

const SplashMP = ({ lexicon }: PageProps): React.ChildrenArray<*> => (
  <>
    <div className="spacer-1" />
    <div className="flexbox-group">
      <MyReproductiveHealthJourney />
      <div className="nav-buttons-container">
        <NavButton
          str={lexicon.get('MyPathSplash')}
          hiddenText={lexicon.get('hiddenNavText')}
          to={isMyPathVAStudy ? '/en/study_mp' : '/en/intro_mp'}
          tabIndex="0"
        />
        {isMyPath4you && (
          <NavButton
            str="en español"
            hiddenText={lexicon.get('hiddenNavText')}
            to="/es/intro_mp"
            tabIndex="0"
          />
        )}
      </div>
    </div>
    <div className="spacer-2" />
    <FooterMP id="" />
  </>
);

export default SplashMP;
