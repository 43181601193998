// @flow

import React from 'react';
import { Provider } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { BrowserRouter, matchPath } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ifvisible from 'ifvisible';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { persistor, store } from '../lib/store';
import { pagesLexicon } from '../lib/lexiconConfig';
import { isMyPath, isMyPathPeripartum, isInControl, isMyBCPeripartum } from '../lib/appMode';
import { myBCRoutes, myPathRoutes, peripartumRoutes, myPathPeripartumRoutes, inControlRoutes } from '../routes';
import './App.scss';

import Rainbow from './shared/rainbow/Rainbow';
import InactivityModal from './shared/InactivityModal';
import Pages from './Pages';

type AppProps = {
  lexicon: Lexicon,
};

/* [Environment setup] */
require('dotenv').config();

let routes = myBCRoutes;

if (isMyPathPeripartum) routes = myPathPeripartumRoutes;
else if (isInControl) routes = inControlRoutes;
else if (isMyPath) routes = myPathRoutes;
else if (isMyBCPeripartum) routes = peripartumRoutes;

class App extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
    this.state = { isInactivityModalOn: false };
  }

  componentDidMount() {
    /* [redux state persist logic] */
    const { pathname } = window.location;
    const match = matchPath('/start/:friendly_id', pathname);

    if (match && match.params) {
      persistor.purge(); // study path 1: clear local storage, then <StartMP> will set userId
    } else if (matchPath('/form', pathname)) {
      persistor.purge(); // study path 2
    } else if (pathname === '/' || pathname === '/en/splash') {
      persistor.purge(); // root path: clear local storage, fetch new user id
      this.fetchNewUser();
    } else if (!store.getState().userId) {
      this.fetchNewUser(); // other paths: maybe fetch new user id
    }

    /* [image preloading] */
    const importAll = (r: Object): Array<string> => r.keys().map(r);
    const imageArr = importAll(require.context('.', true, /\.(png|jpe?g|svg)$/));

    setTimeout(() => {
      imageArr.forEach((imageSrc: string) => {
        const preloadImage = new Image();
        preloadImage.src = imageSrc;
      });
    }, 1000);

    /* [logic to reset app if app is minimized in standalone mode] */
    if (navigator.standalone && !isMyPath) {
      this.minimizeCheckInterval = setInterval(() => {
        if (ifvisible.now('hidden') && pathname !== '/') {
          window.location.href = window.location.host;
        }
      }, 1000);
    }

    /* [508 accessbility]
      add/remove .using-mouse class based on user input via keyboard/mouse;
      this class is used to manage the viability of :focus outline */
    document.body.addEventListener('mousedown', () => { document.body.classList.add('using-mouse'); });
    document.body.addEventListener('keydown', () => { document.body.classList.remove('using-mouse'); });
  }

  fetchNewUser = () => {
    fetch('/users', { method: 'POST' })
      .then((res: string): {} => res.json())
      .then((json: {}) => {
        store.dispatch({
          type: 'SET_USER_ID',
          value: json.id,
        });
      }).catch((err: Error) => {
        console.warn(err);
      });
  };

  /* methods related to handling inactivity */
  onIdle = () => {
    const isAppRunInClinics = !isMyPath && !isInControl;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (window.location.pathname !== '/' && isAppRunInClinics && windowWidth > 719) {
      this.setState({ isInactivityModalOn: true });
    }
  }

  onActive = () => { this.setState({ isInactivityModalOn: false }); }

  onInactivityModalClick = () => { this.setState({ isInactivityModalOn: false }); }

  render() {
    const { isInactivityModalOn } = this.state;
    const { lexicon } = this.props;

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <IdleTimer
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              debounce={250}
              timeout={1000 * 60 * 2}
            />
            {isInactivityModalOn && (
              <InactivityModal
                clickHandler={this.onInactivityModalClick}
                handleTurningOffModal={this.onActive}
              />
            )}

            <Rainbow />

            <BrowserRouter>
              <Pages
                fullLexicon={lexicon}
                routes={routes}
              />
            </BrowserRouter>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    );
  }
}

const EditableApp = (): React.Element => (
  <EditWrapper
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL}
    lexicon={pagesLexicon}
    component={App}
  />
);

export default EditableApp;
